<template>
  <div id="body">
    <div class="header">
      <div class="leftTitle">
        <img src="../../assets/APP_logo.png" />
        <div class="title_text">
          <p>{{detailInfo.advUserName}}</p>
          <!-- <p>代理等级：{{ agentGrade }}级</p> -->
        </div>
      </div>
      <div class="rightTitle">
        <div>
          <span>￥{{$util.func.numFormat($util.func.setMoney(detailInfo.balance))}}</span>
          <span>当前余额</span>
        </div>
        <div>
          <!-- <el-input v-model="money" size="medium" clearable style="width: 100px;"></el-input> -->
          <el-select class="select" v-model="money" size="medium" placeholder="请选择金额" style="width: 120px;">
            <el-option v-for="(item,index) in rechargeMoney" :key="index" :label="item.money" :value="item.value">
            </el-option>
          </el-select>
          <el-button size="medium" type="primary" @click="toRecharge()" style="margin-left:10px;">立即充值</el-button>
        </div>
      </div>
    </div>
    <div class="ClassInfo_box">
      <div class="chartTitle">
        <p class="facility">充值明细</p>
        <div class="datePicker">
          <el-date-picker v-model="date" type="daterange" align="center" value-format="yyyy-MM-dd"
            start-placeholder="开始日期" range-separator="至" end-placeholder="结束日期" clearable @change="serchList()">
          </el-date-picker>
        </div>
      </div>
      <el-table :data="detailData" v-loading="loading">
        <el-table-column prop="id" label="充值ID	" align="center"></el-table-column>
        <el-table-column prop="num" label="充值数量	" align="center">
          <template slot-scope="scope">
            <span>{{$util.func.setMoney(scope.row.num)}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="addTime" label="充值时间" align="center"></el-table-column>
        <el-table-column prop="operateUserIP" label="充值管理员IP" align="center"></el-table-column>
        <el-table-column prop="rechargeAfterBalance" label="充值后余额" align="center">
          <template slot-scope="scope">
            <span>{{$util.func.setMoney(scope.row.rechargeAfterBalance)}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="Username" label="充值管理员名称" align="center"></el-table-column>
        <el-table-column prop="advUserName" label="广告主名称" align="center"></el-table-column>
      </el-table>
      <div class="block">
        <el-pagination @current-change="handleCurrentChange" :current-page="currpage" :page-size="pagesize" background
          layout="prev, pager, next, jumper" :total="CountNum" style="margin-top:15px">
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
  import {
    advRechargeRecord
  } from '@/api/adminApi.js'
  export default {
    data() {
      return {
        detailInfo: JSON.parse(this.$route.params.detailInfo), //广告主信息
        balance: '', //余额
        loading: false, //加载框
        detailData: [], //广告主充值记录列表
        pagesize: 10, // 每页显示条目个数
        currpage: 1, // 初始页
        CountNum: 0, // 总条数
        date: '', // 时间选择
        money: null, //金额选择
        rechargeMoney: [ //充值金额选择
          {
            "money": '10元',
            "value": 10
          }, {
            "money": '50元',
            "value": 50
          }, {
            "money": '100元',
            "value": 100
          }, {
            "money": '200元',
            "value": 200
          }, {
            "money": '500元',
            "value": 500
          }, {
            "money": '1000元',
            "value": 1000
          }
        ]
      }
    },
    created() {
      this.advRechargeRecord({}, 1)
    },
    methods: {
      serchList() {
        let json = {}
        json["startTime"] = this.date[0]
        json["endTime"] = this.date[1]
        this.advRechargeRecord(json, 1)
      },
      toRecharge() { // 点击立即充值
        let data = JSON.stringify(this.detailInfo)
        // let money = JSON.stringify(this.money)
        this.$router.push({
          name: 'advUserRecharge',
          params: {
            detailInfo: data,
            addMoney: this.money
          }
        })
      },
      advRechargeRecord(serchJson, currpage) { // 广告主充值记录列表
        this.loading = true
        const data = serchJson
        data["advUserId"] = this.detailInfo.id //  广告主id
        data["page"] = currpage //	当前页数
        data["limit"] = this.pagesize // 每页条数
        advRechargeRecord(data).then(res => {
          this.CountNum = parseInt(res.data.CountNum) // 总条数
          this.detailData = res.data.List // 充值记录列表
          this.currpage = currpage //分页显示当前页
          this.loading = false
        }).catch(err => {
          console.log(err)
        })
      },
      handleCurrentChange(val) { // 分页
        this.advRechargeRecord({}, val)
      }
    },
    computed: {
      numFormat() { //余额每三位增加逗号
        return function(num) {
          var c = (num.toString().indexOf('.') !== -1) ? num.toLocaleString() : num.toString().replace(
            /(\d)(?=(?:\d{3})+$)/g, '$1,');
          return c;
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  #body {
    min-height: 94%;
    overflow: hidden;
    background-color: #F0F2F5;
  }

  .header {
    display: flex;
    justify-content: space-between;
    height: 110px;
    align-items: center;
    padding-bottom: 30px;
    background: #ffffff;

    .leftTitle {
      display: flex;
      margin-left: 85px;
      align-items: center;

      .title_text {
        margin-left: 24px;

        p:nth-child(1) {
          font-size: 20px;
          color: rgba(0, 0, 0, 0.85);
          font-family: PingFangSC-Medium;
          font-weight: 600;
        }

        p:nth-child(2) {
          width: 121px;
          height: 27px;
          font-size: 14px;
          font-weight: normal;
          background: #1890FF;
          border-radius: 14px;
          color: #FFFFFF;
          line-height: 27px;
        }
      }

      img {
        width: 72px;
        height: 72px;
        border-radius: 50%;
      }
    }

    .rightTitle {
      margin-right: 85px;

      div:nth-child(1) {

        span:nth-child(1) {
          font-size: 30px;
          margin-right: 20px;
          margin-left: -30px;
        }

        span:nth-child(2) {
          font-size: 12px;
          color: #909399;
        }
      }

      div:nth-child(2) {
        margin-top: 20px;
      }
    }
  }


  .ClassInfo_box {
    width: 96%;
    margin: 0 auto;
    background: white;
    margin-top: 25px;

    .chartTitle {
      height: 51px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .facility {
        font-size: 16px;
        height: 30px;
        line-height: 20px;
        color: #1890FF;
        margin-left: 32px;
        font-family: PingFangSC-Medium;
        border-bottom: 1px solid #1890FF;
      }

      .datePicker {
        margin-right: 32px;
        cursor: pointer;
      }
    }
  }
</style>
